:root {
  --color-black: rgba(24, 22, 23, 1);
  --color-white: #FFF;
  --color-blue: rgba(20, 87, 158, 1);
  --color-text: rgba(19, 42, 124, 1);
  --color-orange: rgba(241, 126, 32, 1);
}

/* stylelint-disable scss/comment-no-empty */
.container {
  max-width: 100%;
  width: 1230px;
  padding: 0 15px;
  margin: 0 auto;
}

.header {
  height: 72px;
  background: var(--color-blue);
  display: flex;
  align-items: center;
}
.header .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header__logo {
  width: 190px;
}
.header__menu {
  height: 45px;
  padding: 0 25px;
  margin: 0;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  background: rgba(0, 49, 102, 0.5);
  border-radius: 8px;
}
.header__menu a {
  display: flex;
  align-items: center;
  gap: 5px;
  font-weight: 500;
  font-size: 16px;
  color: #fff;
  text-decoration: none;
  text-transform: uppercase;
}
.header__menu a:hover {
  color: var(--color-orange);
}
.header__burger {
  display: none;
}
.header__btn {
  display: flex;
  gap: 10px;
}

.main {
  height: 360px;
  display: flex;
  align-items: center;
  background-image: url("/assets/img/bg.webp");
  background-size: cover;
  position: relative;
}
.main:before {
  position: absolute;
  content: "";
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.75);
  z-index: 0;
}
.main .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.main__block {
  z-index: 1;
  position: relative;
}
.main__info {
  z-index: 1;
  position: relative;
  width: 440px;
  background: #fff;
  border-radius: 8px;
  padding: 20px;
  padding-top: 55px;
  text-align: center;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.main__img {
  position: absolute;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background: var(--color-orange);
  display: flex;
  align-items: center;
  justify-content: center;
  top: -40px;
  left: auto;
  animation: 4s infinite scaleanim;
  transition: 0.2s;
}
.main__title {
  font-size: 24px;
  font-weight: 700;
  color: #000;
}
.main__price {
  font-size: 48px;
  font-weight: 700;
  margin-bottom: 10px;
}
.main .btn {
  padding: 10px 55px;
  width: max-content;
  margin: 0 auto;
}
.main__block {
  max-width: 50%;
  color: #fff;
}
.main h1 {
  font-size: 51px;
  line-height: 63px;
  padding-bottom: 10px;
  margin-bottom: 20px;
  position: relative;
  color: #fff;
}
.main h1:before {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 210px;
  height: 2px;
  background: var(--color-orange);
  content: "";
}

@keyframes scaleanim {
  0%, 100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
}
.cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-top: 20px;
  margin-bottom: 30px;
}
.cards__card {
  max-width: 290px;
  margin: 0 auto;
  background: #fff;
  border-radius: 8px;
  display: flex;
  align-items: center;
}
.cards__img {
  width: 100px;
  height: 100px;
  flex-shrink: 0;
  opacity: 0.7;
}
.cards__img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}
.cards__content {
  padding: 10px;
  opacity: 0.7;
}
.cards__tags {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}
.cards__tag {
  display: flex;
  align-items: center;
  font-size: 11px;
  line-height: 12px;
  padding: 4px 7px;
  border-radius: 10px;
  color: #fff;
  background: var(--color-orange);
  font-weight: 600;
}
.cards__tag-white {
  background: #fff;
  color: rgb(19, 42, 124);
  border: 1px solid rgb(19, 42, 124);
}
.cards__title {
  font-size: 15px;
  display: flex;
  font-weight: 700;
  align-items: center;
}
.cards p {
  margin: 0;
}

.nav {
  display: grid;
  grid-template-columns: 100px auto;
  align-items: center;
  margin-bottom: 20px;
}
.nav span {
  text-transform: uppercase;
}
.nav ol {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.nav ol a {
  display: block;
  border-radius: 24px;
  background: rgba(21, 56, 114, 0.1);
  color: rgb(19, 42, 124);
  padding: 15px;
}

.columns {
  display: flex;
  gap: 30px;
}
.columns .column {
  width: 100%;
}

.steps {
  display: flex;
  gap: 15px;
  margin-bottom: 25px;
}
.steps .step {
  width: 290px;
  position: relative;
}
.steps .step__text {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #153872 50%);
  padding: 20px;
  color: #fff;
  border-bottom-right-radius: 25px;
  border-bottom-left-radius: 25px;
}
.steps .step__text span {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto 20px;
  width: 60px;
  height: 60px;
  background: #fff;
  border-radius: 50%;
  font-size: 32px;
  font-weight: 900;
  color: rgb(19, 42, 124);
}

h2 {
  font-size: 27px;
  margin-bottom: 25px;
  padding-left: 14px;
  border-left: 6px solid var(--color-orange);
  margin-top: 0;
}

h3 {
  font-size: 21px;
  margin-bottom: 25px;
  margin-top: 0;
}

p {
  margin-top: 0;
  margin-bottom: 20px;
}

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  text-transform: uppercase;
  padding: 15px;
  font-weight: 700;
  gap: 10px;
}
.btn-orange {
  background: var(--color-orange);
  color: #fff;
}
.btn-orange:hover {
  color: #fff;
  opacity: 0.7;
}
.btn-white {
  background: #fff;
  color: #000;
}
.btn-white:hover {
  color: var(--color-orange);
}

.img-cov {
  width: 200px;
  float: left;
  margin: 0 25px 25px 0;
}

.img-text {
  max-width: 1024px;
  margin: 0 auto;
  margin-bottom: 25px;
}

.img-text img {
  width: 100%;
  border-radius: 25px;
}

.table {
  width: 100%;
  overflow-x: auto;
  margin-bottom: 20px;
}
.table table {
  width: 100%;
  border-collapse: collapse;
}
.table tr:nth-child(2n) td {
  background: rgba(21, 56, 114, 0.1);
}
.table td {
  padding: 10px;
  background: rgba(21, 56, 114, 0.05);
}
.table tr:first-child td {
  background: rgb(14, 68, 130);
  color: #fff;
  text-align: center;
  font-weight: 700;
}
.table tr:first-child td:first-child {
  border-top-left-radius: 25px;
}
.table tr:first-child td:last-child {
  border-top-right-radius: 25px;
}
.table tr:last-child td:first-child {
  border-bottom-left-radius: 25px;
}
.table tr:last-child td:last-child {
  border-bottom-right-radius: 25px;
}

.faq__item {
  border-radius: 25px;
  background: rgb(232, 236, 241);
  padding: 50px;
  padding-bottom: 30px;
  margin-bottom: 15px;
  position: relative;
  cursor: pointer;
}
.faq__item:before {
  content: "";
  position: absolute;
  top: 25px;
  right: 30px;
  width: 20px;
  height: 20px;
  background-image: url("/assets/img/arrow.svg");
  transition: all 0.3s linear;
}
.faq__item[aria-expanded=false]:before {
  transform: rotate(180deg);
}
.faq__item[aria-expanded=true] {
  padding-bottom: 50px;
}
.faq__item h3 {
  margin: 0;
  margin-bottom: 20px;
}
.faq__content {
  background: #fff;
  border-radius: 20px;
  height: 0;
  overflow: hidden;
  padding: 0;
  transition: all 0.3s linear;
  cursor: auto;
}
.faq__content p {
  margin: 0;
  padding: 20px 10px;
}

.review {
  border-radius: 25px;
  background: #fff;
  padding: 40px;
  margin-bottom: 100px;
}
.review__item {
  box-shadow: 1px 1px 5px 0px rgb(212, 212, 212);
  margin-bottom: 15px;
  border-radius: 25px;
  padding: 10px;
}
.review__item a {
  font-weight: 600;
}
.review__item p {
  margin: 0;
}
.review__head {
  display: flex;
  justify-content: space-between;
}
.review__name {
  font-weight: 700;
  color: rgb(19, 42, 124);
  font-size: 17px;
}
.review__date {
  font-size: 15px;
  color: rgb(196, 196, 196);
}
.review__body {
  padding: 15px 0;
  display: grid;
  grid-template-columns: auto 110px;
}

.form {
  margin-bottom: 20px;
}
.form span {
  font-size: 20px;
  display: block;
  margin-bottom: 10px;
}
.form textarea {
  border-radius: 8px;
  height: 90px;
  border: 1px solid var(--color-orange);
}
.form input {
  border-radius: 8px;
  border: 1px solid var(--color-orange);
  padding: 10px;
  height: 37px;
  color: rgb(117, 117, 117);
  margin-bottom: 8px;
  width: 100%;
}
.form__row {
  display: grid;
  grid-template-columns: auto 225px;
  gap: 20px;
}

.footer {
  background: rgb(0, 35, 71);
  padding-top: 35px;
}
.footer__info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 10px;
}
.footer__columns {
  display: flex;
  justify-content: space-between;
}
.footer__social {
  display: flex;
  gap: 10px;
  margin-top: 15px;
}
.footer__social a {
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 7px;
  background: rgb(19, 42, 124);
}
.footer__block ul {
  padding: 0;
  margin: 0;
  list-style: none;
  margin-top: 5px;
}
.footer__block ul a {
  color: #fff;
  display: block;
  font-size: 14px;
  margin-bottom: 15px;
  font-weight: 500;
}
.footer__block ul a:hover {
  color: var(--color-orange);
}
.footer__copyright {
  margin-top: 50px;
  background: rgb(45, 76, 128);
  color: #fff;
  padding: 35px 0;
}

.page-404 {
  text-align: center;
  padding: 100px 0;
}
.page-404 .btn {
  width: max-content;
  margin: 0 auto;
}
.page-404 h1,
.page-404 p {
  max-width: 100%;
}

.header__mobile {
  display: none;
}

@media screen and (max-width: 992px) {
  .header {
    display: block;
    height: auto;
    z-index: 1000;
    position: relative;
  }
  .header .container {
    height: 50px;
  }
  .header__logo {
    display: none;
  }
  .header__mobile {
    display: flex;
    background: #fff;
    width: 100%;
    justify-content: center;
    height: 70px;
  }
  .header__menu {
    position: absolute;
    top: 50px;
    left: 0;
    right: 0;
    background: rgb(20, 87, 158);
    display: block;
    padding: 15px;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease;
    z-index: 1;
    height: auto;
    border-radius: 0;
  }
  .header__menu.active {
    opacity: 1;
    visibility: visible;
  }
  .header__menu a {
    padding: 5px 0;
  }
  .header__burger {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 30px;
    height: 30px;
    padding: 2.5px 0;
    z-index: 101;
    cursor: pointer;
  }
  .header__burger span {
    height: 3px;
    width: 30px;
    border-radius: 30px;
    background: #fff;
    display: block;
    transition: all 0.3s ease;
  }
  .header__burger.active span:nth-child(1) {
    transform: translateY(4px) rotate(45deg);
  }
  .header__burger.active span:nth-child(2) {
    transform: translateY(-9px) rotate(-45deg);
  }
  .header__burger.active span:nth-child(3) {
    display: none;
  }
  .main .container {
    display: block;
  }
  .cards {
    display: block;
  }
  .main {
    height: auto;
    padding-bottom: 30px;
  }
  .header .btn {
    font-size: 12px;
    padding: 7px 15px;
  }
  .main__block {
    max-width: 100%;
  }
  .main h1 {
    font-size: 27px;
    line-height: 33px;
    margin-top: 55px;
  }
  .main__info {
    margin-top: 60px;
    width: 100%;
  }
  .cards__card {
    width: 100%;
    margin-bottom: 15px;
    max-width: 100%;
  }
  .nav {
    display: block;
    text-align: center;
    margin-bottom: 40px;
  }
  .nav ol a {
    font-size: 12px;
    padding: 5px 10px;
  }
  .nav span {
    font-size: 24px;
    margin-bottom: 7px;
    display: block;
  }
  .columns {
    display: block;
  }
  .steps {
    display: block;
  }
  .steps .step {
    margin: 0 auto 15px;
  }
  .faq__item {
    padding: 30px;
    padding-bottom: 10px;
  }
  .faq__item[aria-expanded=true] {
    padding-bottom: 30px;
  }
  .form__row {
    display: block;
  }
  .review__body {
    display: flex;
    flex-direction: column-reverse;
    gap: 15px;
  }
  .review {
    padding: 20px;
  }
  .footer .btn {
    padding: 5px;
    font-size: 12px;
  }
  .footer__columns {
    flex-direction: column;
    align-items: center;
    gap: 30px;
  }
  .footer__block ul a {
    text-align: center;
  }
}