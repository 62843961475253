:root {
  --color-black: rgba(24, 22, 23, 1);
  --color-white: #FFF;
  --color-blue: rgba(20, 87, 158, 1);
  --color-text: rgba(19, 42, 124, 1);
  --color-orange: rgba(241, 126, 32, 1);
}

$font-primary: Roboto, -apple-system, Roboto, sans-serif;

$fz: 16px;
$lh: calc(26 / 16);

$min-screen: 320px;