/* stylelint-disable scss/comment-no-empty */

// 100 – Thin.
// 200 – Extra Light (Ultra Light)
// 300 – Light.
// 400 – Normal.
// 500 – Medium.
// 600 – Semi Bold (Demi Bold)
// 700 – Bold.
// 800 – Extra Bold (Ultra Bold)

@mixin font-face($font-family, $url, $weight: normal, $style: normal) {
    @font-face {
        font-weight: $weight;
        font-family: "#{$font-family}";
        font-style: $style;
        font-display: swap;
        src: url("#{$url}.woff2") format("woff2");
    }
}
